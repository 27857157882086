import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';

import liveRoom from 'assets/images/live-room-screenshot.png';
import liveRoomWebp from 'assets/images/live-room-screenshot.png?as=webp';
import badge01 from 'assets/images/badge-01.png';
import badge01Webp from 'assets/images/badge-01.png?as=webp';
import badge02 from 'assets/images/badge-02.png';
import badge02Webp from 'assets/images/badge-02.png?as=webp';
import badge03 from 'assets/images/badge-03.png';
import badge03Webp from 'assets/images/badge-03.png?as=webp';

import appleStore from 'assets/images/apple-store.svg';
import googlePlayStore from 'assets/images/google-play-store.svg';
import { selectLocalizationData } from '../../features/localization/localizationSlice';
import { SUPPORTED_REGIONS } from '../../../shared/enums';

const LazyImg = loadable(() => import('../common/LazyImg'));
const FadeIn = loadable(() => import('../common/FadeIn'));

const badgeImages = [
  {
    webp: `${badge01Webp}`,
    png: `${badge01}`,
    alt: 'Best smart air purifier',
  },
  {
    webp: `${badge02Webp}`,
    png: `${badge02}`,
    alt: 'A truly smart air purifier',
  },
  {
    webp: `${badge03Webp}`,
    png: `${badge03}`,
    alt: 'Best air purifier',
  },
];

const AppHero = () => {
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0);
  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ awaitImgLoad, setAwaitImgLoad ] = useState(false);
  const localization = useSelector(selectLocalizationData);
  const isRegionMatch = localization.region === SUPPORTED_REGIONS.CA;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex === badgeImages.length - 1 ? 0 : prevIndex + 1));
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [ currentImageIndex ]);

  useEffect(() => {
    if (!isImgLoading) return;
    const timer = setTimeout(() => {
      setAwaitImgLoad(true);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [ isImgLoading ]);

  return (
    <section className='app-hero'>
      <div className='container'>
        <div className='app-hero__image app-hero__image--for-phone '>
          <figure className='img-relative'>
            {!awaitImgLoad && <div className='image-placeholder' style={{ aspectRatio: `${491 / 757}` }} />}
            <LazyLoad className={`${!awaitImgLoad ? 'img-absolute' : ''}`}>
              <FadeIn>
                <picture>
                  <source srcSet={liveRoomWebp} type='image/webp' />
                  <source srcSet={liveRoom} type='image/png' />
                  <img src={liveRoom} onLoad={() => setIsImgLoading(true)} alt='It’s not widgetsman. It’s widgets, man.' />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
        </div>
        <div className='app-hero__header'>
          <h1 className='app-hero__heading'>
            Keep Mila in the
            {' '}
            <br />
            {' '}
            palm of your hand.
          </h1>
          <div className='app-hero__description'>
            <p>Take a spin through our app and see why Mila is so much more than some ordinary, sit-in-the-corner air purifier.</p>
          </div>
        </div>
        <div className='app-hero__grid'>
          <div className='app-hero__image app-hero__image--for-desktop'>
            <figure className='img-relative'>
              {!awaitImgLoad && <div className='image-placeholder' style={{ aspectRatio: `${491 / 757}` }} />}
              <LazyLoad className={`${!awaitImgLoad ? 'img-absolute' : ''}`}>
                <FadeIn>
                  <picture>
                    <source srcSet={liveRoomWebp} type='image/webp' />
                    <source srcSet={liveRoom} type='image/png' />
                    <img src={liveRoom} alt='It’s not widgetsman. It’s widgets, man.' onLoad={() => setIsImgLoading(true)} />
                  </picture>
                </FadeIn>
              </LazyLoad>
            </figure>
          </div>
          <div className='app-hero__content'>
            <div className='app-hero__quote-card'>
              <svg className='icon icon-stars'><use xlinkHref='#icon-stars' /></svg>
              <blockquote className='app-hero__quote'>
                <p>
                  “A mindful product that creates awareness of healthy air quality
                  with a simple and intuitive
                  {' '}
                  <br />
                  app interface that is totally customizable to your needs!“
                </p>
                <cite className='app-hero__author'>
                  <strong>— Zach G.</strong>
                  {' '}
                  <br />
                  Green Bay, WI
                </cite>
              </blockquote>
            </div>
            <div className='app-hero__buttons'>
              <a
                href={`${isRegionMatch ? 'https://apps.apple.com/ca/app/mila-air/id1520776049' : 'https://apps.apple.com/us/app/mila-air/id1520776049'}`}
                target='_blank'
                className='btn btn--app-ios'
                rel='noreferrer'
              >
                <LazyImg
                  className='btn__apple-icon'
                  src={appleStore}
                  alt='App Store'
                />
                App Store
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.mila.androidv2'
                target='_blank'
                className='btn btn--app-android'
                rel='noreferrer'
              >
                <LazyImg
                  src={googlePlayStore}
                  alt='Google Play Store'
                />
                Google Play
              </a>
            </div>
            <div className='app-hero__badge'>
              {badgeImages?.map((image, i) => (
                <div className='app-hero__badge-slide'>
                  {currentImageIndex === i && (
                    <figure className='app-hero__badge-image'>
                      <FadeIn>
                        <picture>
                          <source srcSet={image.webp} type='image/webp' />
                          <source srcSet={image.png} type='image/png' />
                          <img src={image.png} alt={image.alt} />
                        </picture>
                      </FadeIn>
                    </figure>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppHero;
